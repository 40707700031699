










import Vue from "vue";
import NavDrawer from "@/components/NavDrawer/index.vue";
import Snackbars from "@/components/Snackbars/index.vue";

export default Vue.extend({
  name: "AppTemplate",
  components: {
    Snackbars,
    NavDrawer,
  },
});
