































import Vue from "vue";
import { SnackbarProps } from "@/lib/store/snackbar";

export default Vue.extend({
  name: "Snackbars",

  computed: {
    snackbars(): SnackbarProps[] {
      return this.$store.getters["snackbar/snackbars"];
    },
  },

  updated() {
    this.$nextTick(() => {
      if (Array.isArray(this.$refs.snackbars)) {
        const topSnacks: Vue[] = [];
        const bottomSnacks: Vue[] = [];

        // Group top and bottom aligned snackbars together.
        this.$refs.snackbars.forEach((snackbar: Vue | Element) => {
          if ((snackbar as Vue).$props.top === true) {
            topSnacks.push(snackbar as Vue);
          } else {
            bottomSnacks.push(snackbar as Vue);
          }
        });

        if (topSnacks.length > 0) {
          this.adjustMultipleSnackbarPosition(topSnacks);
        }

        if (bottomSnacks.length > 0) {
          this.adjustMultipleSnackbarPosition(bottomSnacks, true);
        }
      }
    });
  },

  methods: {
    /**
     * Adjusts the position of multiple snackbars to stack.
     *
     * @note This is a quick fix for stacking snackbars and SHOULD be removed
     * once there is support for it in the `v-snackbar` component.
     *
     * @param {Vue[]} snackbars
     * @param {boolean} isBottom
     */
    adjustMultipleSnackbarPosition(snackbars: Vue[], isBottom = false): void {
      // The bottom or top margin of a snackbar is default by 8px.
      // Change this if it has been changed.
      const marginY = 8;
      let totalYOffset = 0;

      // Adjust in reverse order as we want to push the oldest snackbar.
      for (let i = snackbars.length - 1; i >= 0; i--) {
        const snackbar = snackbars[i];

        const $wrapper: HTMLElement | null = snackbar.$el.querySelector(
          ".v-snack__wrapper"
        );

        if (!$wrapper) {
          return;
        }

        const height = $wrapper.clientHeight;

        if (height > 0) {
          // Do not adjust margins to first snackbar.
          $wrapper.style[isBottom ? "marginBottom" : "marginTop"] =
            totalYOffset + marginY + "px";

          totalYOffset += height + marginY;
        }
      }
    },

    /**
     * Handle when a snackbar is closed.
     */
    closeSnackBar(index: number) {
      // We want to remove the snack bars from the collection in the store.
      this.$store.commit("snackbar/hide", index);
    },
  },
});
