
















import Vue from "vue";

// eslint-disable-next-line no-undef
declare let cordova: Cordova & {
  getAppVersion: {
    getVersionNumber: (cb: (version: string | null) => void) => void;
  };
};
declare let window: Window & {
  version?: string;
};

export default Vue.extend({
  name: "SettingsForm",
  data() {
    return {
      version: null as string | null,
      appVersion: null as string | null,
      settings: {
        isBetaPlatform: false,
      },
    };
  },
  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },

  watch: {
    settings: {
      deep: true,
      handler(nv) {
        this.$store.commit("app/setSettings", {
          isBetaPlatform: nv.isBetaPlatform === true,
        });
      },
    },
  },
  created() {
    if (
      Object.prototype.hasOwnProperty.call(window, "version") &&
      window.version
    ) {
      this.version = window.version;
    }

    if (typeof cordova !== "undefined" && cordova) {
      cordova.getAppVersion.getVersionNumber((version: string | null) => {
        if (version !== null) {
          this.appVersion = version;
        }
      });
    }
  },
  mounted() {
    this.settings = this.$store.getters["app/settings"];
  },
});
